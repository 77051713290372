<template>
  <div id="app">
    <div class="top">停餐
      <div class="top_l iconfont" @click="$router.push({path: '/bundle_calendar'})">&#xeac3;</div>
    </div>

    <!-- 选择餐费包 -->
    <div class="bg_green">

      <div class="white_box3 ">
        <!-- 标题 -->
        <div class="orange_title" style="font-weight: normal; font-size:14px">选择餐包</div>
        <div class="white_box3_in pdb_0">

          <!-- 列表 -->
          <van-checkbox-group v-model="productBundleIds" ref="checkboxGroup" @change="change">
            <div class="product_bundle under_line pd_3p6_0 clear" v-for="(option,index) in productBundleOptions"
                 :key="index">
              <div class="product_bundle_msg checkbox">
                <van-checkbox :name="option.id" :value="option.id" shape="square"
                              checked-color="#34AC40"
                              icon-size="12px">
                </van-checkbox>
              </div>
              <div>
                <div class="product_bundle_msg fw_bold">
                  <span>{{ option.name }}</span>
                </div>
                <div class="product_bundle_msg" style="color: #999999; font-weight: normal; margin-top: 2px">
                  <span
                      v-if="option.stopAndEnrollAdvance<=0">请于用餐日期的 当天{{
                      option.stopAndEnrollTimePoint ? option.stopAndEnrollTimePoint.substring(0, 2) : ''
                    }}点前 提交</span>
                  <span
                      v-if="option.stopAndEnrollAdvance>0">请于用餐日期的 前{{
                      option.stopAndEnrollAdvance
                    }}天{{
                      option.stopAndEnrollTimePoint ? option.stopAndEnrollTimePoint.substring(0, 2) : ''
                    }}点前 提交</span>
                </div>
              </div>
            </div>
          </van-checkbox-group>
        </div>
      </div>
    </div>

    <!-- 开始日期 -->
    <div class="white_box3 mgt_3vw">

      <!-- 标题 -->
      <div class="dark_title" style="font-weight: normal;">选择开始日期</div>

      <div class="white_box3_in pdb_0">

        <!-- 日期选择 -->
        <div class="user under_line pd_3p6_0 clear">
          <div class="user_msg">
            <div class="calendar_txb" @click="showCalendar1 = true">{{ startDay }}</div>
          </div>
          <div class="user_r2" @click="showCalendar1 = true">
            <img src="../assets/images/ico_calendar.png" alt=""></div>
        </div>

      </div>

    </div>

    <!-- 结束日期 -->
    <div class="white_box3 mgt_3vw">

      <!-- 标题 -->
      <div class="dark_title" style="font-weight: normal;">选择结束日期</div>

      <div class="white_box3_in pdb_0">

        <!-- 日期选择 -->
        <div class="user under_line pd_3p6_0 clear">
          <div class="user_msg">
            <div class="calendar_txb" @click="showCalendar2 = true">{{ endDay }}</div>
          </div>
          <div class="user_r2" @click="showCalendar2 = true"><img src="../assets/images/ico_calendar.png"
                                                                  alt=""></div>
        </div>

      </div>

    </div>

    <button class="button_green large" @click="stop">提交</button>

    <!-- 弹出开始日期 -->
    <van-calendar v-model="showCalendar1" @confirm="onConfirm1"/>

    <!-- 弹出结束日期 -->
    <van-calendar v-model="showCalendar2" @confirm="onConfirm2"/>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<style scoped>
::v-deep .van-checkbox {
  margin-left: 10px;
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_TOKEN, ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {formatDate} from '@/utils/util'
import {Dialog} from 'vant';
import Notify from "vant/es/notify";

export default {
  data() {
    return {
      img: require('../assets/images/avatar.png'),

      checked: false,
      checked2: false,

      showCalendar1: false,

      showCalendar2: false,

      loading: false,
      productBundleOptions: [],
      productBundleIds: [],
      startDay: '/　　/',
      endDay: '/　　/',
      type: 'STOP',
      advance: {},
      point: {}
    };
  },
  mounted() {
    this.customerUser = Vue.ls.get(ACCESS_CustomerUser)

    this.loading = true
    let url = 'api/business/v2/{businessId}/product/bundle/{businessCustomerUserId}/search/reservation'
    url = url.replace('{businessId}', this.customerUser.businessId)
    url = url.replace('{businessCustomerUserId}', this.customerUser.businessCustomerUserId)

    post(url, {}).then(res => {
      this.productBundleOptions = res.data;
      // if (!this.productBundleOptions || this.productBundleOptions.length == 0) {
      //     Dialog.alert({message: '您还没购买过餐费包，请购买后再进行操作'}).then(() => {
      //         this.$router.back()
      //     });
      // }
      this.loading = false
    }).catch(() => this.loading = false)

    post('api/business/v2/{businessId}/setting/search'.replace('{businessId}', this.customerUser.businessId), {
      size: 500
    }).then(res => {
      this.advance = res.data.rows.find(item => item.setKey === 'STOPANDENROLLADVANCE'); // 换餐提前申请天数
      this.point = res.data.rows.find(item => item.setKey === 'STOPANDENROLLPOINT');//
    }).catch(() => {
    });
  },
  methods: {
    onConfirm1(date) {
      let month = date.getMonth() + 1;
      this.startDay = `${date.getFullYear()} / ${month < 10 ? '0' + month : month} / ${date.getDate()}`;
      this.showCalendar1 = false;
    },
    onConfirm2(date) {
      let month = date.getMonth() + 1;
      this.endDay = `${date.getFullYear()} / ${month < 10 ? '0' + month : month} / ${date.getDate()}`;
      this.showCalendar2 = false;
    },
    stop() {
      let start = this.startDay && this.startDay !== '/　　/' ? parseInt(this.startDay.split('/')[0]) + '/' + parseInt(this.startDay.split('/')[1]) + '/' + parseInt(this.startDay.split('/')[2]) : '';
      let end = this.endDay && this.endDay !== '/　　/' ? parseInt(this.endDay.split('/')[0]) + '/' + parseInt(this.endDay.split('/')[1]) + '/' + parseInt(this.endDay.split('/')[2]) : '';

      if (!start) {
        Notify({type: 'warning', message: '请填写开始日期'});
        return;
      }

      if (!end) {
        Notify({type: 'warning', message: '请填写结束日期'});
        return;
      }

      if (this.productBundleIds.length === 0) {
        Notify({type: 'warning', message: '请选择您要操作的餐包'});
        return;
      }

      this.loading = true
      post('api/reservation/v2/stop', {
        businessCustomerUserId: this.customerUser.businessCustomerUserId,
        startDay: new Date(start + ' 00:00:00').getTime(),
        endDay: new Date(end + ' 23:59:59').getTime(),
        type: this.type,
        // "extraMeal": "",
        productBundleIds: this.productBundleIds
      }).then(res => {
        Dialog.alert({message: '提交成功'}).then(() => {
          this.$router.back()
        });
        this.loading = false
      }).catch(error => {
        Notify({type: 'warning', message: error.message});
        this.loading = false
      });

    }
  }

};
</script>
